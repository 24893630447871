/*
  Plays popup video which has a video file
*/

export function videoPopupPlayerFile() {
  // var videoSrc;
  // var videoControls;
  // $('.video-iframe-thumbnail.video-file').click(function () {
  //   videoSrc = $(this).data('src');
  //   videoControls = $(this).data('controls');
  //   console.log(videoSrc);
  // });

  // $('#myModal-video-file.video-file').on('shown.bs.modal', function () {
  //   console.log(videoSrc);
  //   $('#myModal-video-file #video').attr(
  //     'src',
  //     videoSrc + '?autoplay=1&mute=1&amp&modestbranding=1&amp&'+ videoControls+'&showinfo=0;'
  //   );
  // });

  // $('#myModal-video-file').on('hide.bs.modal', function () {
  //   $('#myModal-video-file #video').attr('src', '');
  // });

  $('.video-section.v2.file').each(function() {
    var video;
    var videoControls;
    let videoOverlay = $(this).find('.video-overlay');

    $(videoOverlay).click(function(){
      video = $(this).siblings('.video-file').data('src');
      videoControls = $(this).siblings('.video-file').data('controls');
    });

    $('#myModal-video-file.video-file').on('shown.bs.modal', function () {
      $('#myModal-video-file #video').attr(
        'src',
        video + '?autoplay=1&muted=1&mute=1&modestbranding=1&'+ videoControls+'&showinfo=0;'
      );
    });

    $('#myModal-video-file').on('hide.bs.modal', function () {
      $('#myModal-video-file #video').attr('src', '');
    });
  });
}