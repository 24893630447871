/*
  Plays full width video player which has a video file
*/

export function fullWidthVideoPlayerFile() {
  // $('.play-video-file-wrp .playvideo.video-file').click(function() {
  //   $('.play-video-file-wrp').each(function() {
  //     let videoEl = $(this).children('video').get(0);
  //     if (videoEl.currentTime > 0 && !videoEl.paused && !videoEl.ended && videoEl.readyState > 2) {
  //       videoEl.pause();
  //     }
  //     $(this).children('video').css('opacity', 0);
  //     $(this).children('.video-thumb').css({
  //         'opacity' : '1',
  //         'z-index' : '1',
  //     });
  //   });

  //   $(this).siblings('.iframe-video').css('opacity','1');
  //   $(this).siblings('.iframe-video').get(0).play();
  //   $(this).css({
  //     'opacity' : '0',
  //     'z-index' : '-1',
  //   });
  // });

  $('.video-section.v1.file').each(function() {
    let videoOverlay = $(this).find('.video-overlay');

    $(videoOverlay).click(function(){
      let video = $(this).siblings('.iframe-video');
      let videoThumb = $(this).siblings('.video-thumb');

      if (video.currentTime > 0 && !video.paused && !video.ended && video.readyState > 2) {
        video.pause();
      }

      video.css('opacity', '1');
      video.get(0).play();
      videoThumb.css({
        'opacity' : '0',
        'z-index' : '-1',
      });
      videoOverlay.css({
        'opacity' : '0',
        'z-index' : '-1',
      });
    });
  });
}