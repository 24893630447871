/*
  Implements the form select2 wrapper
*/

import 'select2/dist/js/select2.min.js';

export function formScripts() {
  if ($('.form-dropdown')) {
    $('.form-dropdown').each(function() {
      $(this).select2({
        dropdownParent: $(this).parent().parent(),
      });
    });
  }

  $('form').each(function() {
    $(this).find('.button-wrp-outter').append($(this).find('.wpcf7-response-output'));
  });
}