/*
  Add functionality to "Add Buttons" in WYSIWYG
*/

// export function editorContentAdditions() {
//   //wrap all buttons with a wrapper to handle responsive - This works with "Add Buttons" feature in the wp editor
//   // var target = '.btn',
//   //     invert = ':not(' + target + ')',
//   //     // wrap = '<div class="button-wrapper">',
//   //     breakpoints = $('.editor-content-wrp > *'+invert),
//   //     wrap;

//   // $('.editor-content-wrp').each(function() {
//   //   var wrapStyles = ($(this).find('.btn').parent('p').attr('style'));
//   //   $(this).find('.btn').unwrap();

//   //   if (wrapStyles == 'text-align: right;') {
//   //     wrap = ('<div class="button-wrapper" style="justify-content: flex-end;">');
//   //   } else if (wrapStyles == 'text-align: center;') {
//   //     wrap = ('<div class="button-wrapper" style="justify-content: center;">');
//   //   } else {
//   //     wrap = ('<div class="button-wrapper" style="justify-content: flex-start;">');
//   //   }

//   // breakpoints.each(function(){
//   //   $(this).nextUntil(invert).wrapAll(wrap);
//   // });

//   // breakpoints.first().prevUntil(invert).wrapAll(wrap);
//   //wrap all buttons with a wrapper to handle responsive - This works with "Add Buttons" feature in the wp editor
// }

export function editorContentAdditions() {
  //wrap all buttons with a wrapper to handle responsive - This works with 'Add Buttons' feature in the wp editor
  var target = '.btn',
      invert = ':not(' + target + ')',
      wrap = '<div class="button-wrapper">',
      breakpoints = $('.editor-content-wrp > *'+invert),
      inlineStyle,
      wrapStyles = [];

  $('.editor-content-wrp .btn').each(function( ) {
    wrapStyles.push($(this).parent().attr('style'))
  });

  $('.editor-content-wrp').each(function( ) {
    $(this).find('.btn').unwrap();
  });

  breakpoints.each(function(){
    $(this).nextUntil(invert).wrapAll(wrap);
  });

  breakpoints.first().prevUntil(invert).wrapAll(wrap);

  let stylePosition = 0;

  $('.editor-content-wrp .button-wrapper').each(function() {
    let childrenCount = $(this).children().length;

    if (wrapStyles[stylePosition]) {
      if (wrapStyles[stylePosition] == 'text-align: right;') {
        inlineStyle = ('justify-content: flex-end;');
      } else if (wrapStyles[stylePosition] == 'text-align: center;') {
        inlineStyle = ('justify-content: center;');
      } else {
        inlineStyle = ('justify-content: flex-start;');
      }

      $(this).attr('style', inlineStyle);
    }

    stylePosition = stylePosition + childrenCount;
  });
  //wrap all buttons with a wrapper to handle responsive - This works with 'Add Buttons' feature in the wp editor
}