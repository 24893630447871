/*
  Implements the form select2 wrapper
*/

import 'select2/dist/js/select2.min.js';

export function formScripts() {
  if ($('.form-dropdown')) {
    $('.form-dropdown').each(function() {
      var dropDownPlaceHolder = $(this).parents('.select2-wrapper').attr('data-placeholder');

      if($(this).parents('.select2-wrapper').attr('data-placeholder') !== undefined) {
        dropDownPlaceHolder = $(this).parents('.select2-wrapper').attr('data-placeholder');
      } else {
        dropDownPlaceHolder = 'Please Select';
      }

      $(this).select2({
        dropdownParent: $(this).parent().parent(),
        placeholder: dropDownPlaceHolder,
      });
    });
  }

  $('form').each(function() {
    $(this).find('.button-wrp-outter').append($(this).find('.wpcf7-response-output'));
  });
}