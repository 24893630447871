import 'flickity/dist/flickity.pkgd.js';
import Flickity from 'flickity';
import 'viewerjs/dist/viewer.min.js';
import 'jquery-viewer/dist/jquery-viewer.min.js';

import { cookieConsent } from './scripts/cookie/cookieConsent';
import { deleteCookie } from './scripts/cookie/deleteCookie';
import { fullWidthVideoPlayerFile } from './scripts/video/fullWidthVideoPlayerFile';
import { videoPopupPlayerFile } from './scripts/video/videoPopupPlayerFile';
import { delayHomeBannerVideoFile } from './scripts/video/delayHomeBannerVideoFile';
import { headerScripts } from './scripts/header/headerScripts';
import { stickyMenu } from './scripts/header/stickyMenu';
import { loadMore } from './scripts/load-more/loadMore';
import { formScripts } from './scripts/form/formScripts';
import { cardSectionGridSlider } from './scripts/card/cardSectionGridSlider';
import { cardSectionSlider } from './scripts/card/cardSectionSlider';
import { logoSlider } from './scripts/logo/logoSlider';
import { logoSectionGridSlider } from './scripts/logo/logoSectionGridSlider';
import { gallerySectionSlider } from './scripts/gallery/gallerySectionSlider';
import { jqueryViewer } from './scripts/gallery/jqueryViewer';
import { editorContentAdditions } from './scripts/misc/editorContentAdditions';
import { testimonialSlider } from './scripts/testimonial/testimonialSlider';
import { testimonialGridSlider } from './scripts/testimonial/testimonialGridSlider';

// Flickity.prototype._createResizeClass = function() {
//   this.element.classList.add('flickity-resize');
// };

// Flickity.createMethods.push('_createResizeClass');

// var resize = Flickity.prototype.resize;
// Flickity.prototype.resize = function() {
//   this.element.classList.remove('flickity-resize');
//   resize.call( this );
//   this.element.classList.add('flickity-resize');
// };

export default {
  init() {
    // JavaScript to be fired on all pages
    Flickity.defaults.dragThreshold = 3;
  },
  finalize() {
    // JavaScript to be fired on all pages, after page specific JS is fired
    logoSlider();
    cookieConsent();
    deleteCookie('PREF');
    headerScripts();
    stickyMenu();
    logoSectionGridSlider();
    formScripts();
    cardSectionGridSlider();
    cardSectionSlider();
    gallerySectionSlider();
    jqueryViewer('.gallery-images-viewer-v2');
    jqueryViewer('.gallery-images-viewer-v1');
    fullWidthVideoPlayerFile();
    videoPopupPlayerFile();
    delayHomeBannerVideoFile();
    editorContentAdditions();
    loadMore();
    testimonialGridSlider();
    testimonialSlider();
  },
};

// flickity resize to work when specific amount of pixels are resized eg: now at 10px
const resizeDelta = Math.abs($(window).width() - $(window).data('previousWidth'));

if (resizeDelta > 10) {
  $('.flickity-viewport').css('height', '400px');
}

$(window).data('previousWidth', $(window).width());
// flickity resize to work when specific amount of pixels are resized eg: now at 10px

$(window).on('resize', function () {
  // $('.cards-section.slider .cards-wrp, .cards-section.grid .cards-wrp, .gallery-section.v1 .gallery-wrapper, .testimonials-section .testimonials-row'
  // ).flickity('resize');

  // set header height to sticky overlay
  $('.sticky-overlay').outerHeight($('.header').outerHeight());
  $('.main > section:first-child:not(".home-banner-section, .header-variation-active")').css('margin-top', $('.header').outerHeight());
});

$(window).on('load', function () {
  $('.cards-section.slider .cards-wrp, .cards-section.grid .cards-wrp, .gallery-section.v1 .gallery-wrapper, .testimonials-section .testimonials-row'
  ).flickity('resize');
});
