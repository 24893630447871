/*
  Cards section grid slider new
*/

export function cardSectionSlider() {
  var cellAlignValue = '';

  if (matchMedia('screen and (max-width: 768px)').matches) {
    cellAlignValue = 'center';
  } else {
    cellAlignValue = 'left';
  }

  $('.cards-section.slider .cards-wrp').flickity({
    pageDots: true,
    prevNextButtons: true,
    wrapAround: true,
    cellAlign: cellAlignValue,
  });

  $('.cards-section.slider').each(function () {
    $(this).find('.flickity-button').appendTo($(this).find('.flickity-button-wrp'));
  });
}