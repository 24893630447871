/*
  Set the functionality of header navigation
*/

export function headerScripts() {
  let menuItemL1 = $('.mobile-menu .nav > li.menu-item');
  let menuItemL2 = $('.mobile-menu .nav > li.menu-item-has-children > .sub-menu-wrap li.menu-item');
  let subMenuArrowWrp = '<span class="sub-menu-arrow"></span>';
  let showMenuClass = 'show-menu';

  // toggle class on hamburger icon click
  $('.mobile-burger-icon').click(function () {
    $('body, .mobile-burger-icon').toggleClass(showMenuClass);
  });

  menuItemL1.each(function() {
    $(this).find('> a').append(subMenuArrowWrp);
    $(this).find('> .sub-menu-wrap > .sub-menu').prepend('<div class="return-link">' + $(this).find('> a').text() + '</div>');

    if (($(this).hasClass('menu-item-has-children') == true) && ($(this).find('> a').attr('href') === '#')) {
      $(this).find('> a').click(function (e) {
        e.preventDefault();
        $(this).siblings('.sub-menu-wrap').toggleClass(showMenuClass);
      });
    } else if (($(this).hasClass('menu-item-has-children') == true) && ($(this).find('> a').attr('href') !== '#')) {
      $(this).find('> a > .sub-menu-arrow').click(function (e) {
        e.preventDefault();
        $(this).parent('a').siblings('.sub-menu-wrap').toggleClass(showMenuClass);
      });
    }
  });

  menuItemL2.each(function() {
    $(this).find('> a').append(subMenuArrowWrp);
    $(this).find('> .sub-menu-wrap > .sub-menu').prepend('<div class="return-link">' + $(this).find('> a').text() + '</div>');

    if (($(this).hasClass('menu-item-has-children') == true) && ($(this).find('> a').attr('href') === '#')) {
      $(this).find('> a').click(function (e) {
        e.preventDefault();
        $(this).siblings('.sub-menu-wrap').toggleClass(showMenuClass);
      });
    } else if (($(this).hasClass('menu-item-has-children') == true) && ($(this).find('> a').attr('href') !== '#')) {
      $(this).find('> a > .sub-menu-arrow').click(function (e) {
        e.preventDefault();
        $(this).parent('a').siblings('.sub-menu-wrap').toggleClass(showMenuClass);
      });
    }
  });

  // mobile sub menu navigation
  // level 1
  // $('.mobile-menu .nav > li.menu-item-has-children').each(function () {
  //   $(this).find('> .sub-menu-wrap > .sub-menu').prepend('<div class="return-link">' + $(this).find('> a').text() + '</div>');
  //   $(this).find('> a').click(function (e) {
  //     e.preventDefault();
  //     $(this).siblings('.sub-menu-wrap').toggleClass('show-menu');
  //   });
  // });

  // level 2
  // $('.mobile-menu .nav > li.menu-item-has-children > .sub-menu-wrap li.menu-item-has-children').each(function () {
  //   $(this).find('> .sub-menu-wrap > .sub-menu').prepend('<div class="return-link">' + $(this).find('> a').text() + '</div>');
  //   $(this).find('> a').click(function (e) {
  //     e.preventDefault();
  //     $(this).siblings('.sub-menu-wrap').toggleClass('show-menu');
  //   });
  // });

  // return link click
  $('.return-link').each(function () {
    $(this).click(function () {
      $(this).parent().parent('.sub-menu-wrap').removeClass(showMenuClass);
    });
  });

  // set header height to sticky overlay
  $('.sticky-overlay').outerHeight($('.header').outerHeight());
  $('.main > section:first-child:not(".home-banner-section, .header-variation-active")').css('margin-top', $('.header').outerHeight());
}