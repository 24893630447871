/*
  Plays popup video which has a youtube/vimeo link
*/

export function videoPopupPlayerLink() {
  // var videoSrc;
  // var videoControls;
  // $('.video-iframe-thumbnail.video-url').click(function () {
  //   videoSrc = $(this).data('src');
  //   videoControls = $(this).data('controls');
  // });

  // $('#myModal-video-url.video-url').on('shown.bs.modal', function () {
  //   $('#myModal-video-url #video').attr(
  //     'src',
  //     videoSrc + '?autoplay=1&muted=1&mute=1&modestbranding=1&'+ videoControls+'&showinfo=0;'
  //   );
  // });

  // $('#myModal-video-url').on('hide.bs.modal', function () {
  //   $('#myModal-video-url #video').attr('src', '');
  // });

  $('.video-section.v2.link').each(function() {
    var video;
    var videoControls;
    let videoOverlay = $(this).find('.video-overlay');

    $(videoOverlay).click(function(){
      video = $(this).siblings('.video-url').data('src');
      videoControls = $(this).siblings('.video-url').data('controls');
    });

    $('#myModal-video-url.video-url').on('shown.bs.modal', function () {
      $('#myModal-video-url #video').attr(
        'src',
        video + '?autoplay=1&muted=1&mute=1&modestbranding=1&'+ videoControls+'&showinfo=0;'
      );
    });

    $('#myModal-video-url').on('hide.bs.modal', function () {
      $('#myModal-video-url #video').attr('src', '');
    });
  });
}