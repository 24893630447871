/*
  Testimonial grid slider
*/

export function testimonialGridSlider() {
  var cellAlignValue = '';

  if (matchMedia('screen and (max-width: 768px)').matches) {
    cellAlignValue = 'center';
  } else {
    cellAlignValue = 'left';
  }

  $('.testimonials-section.grid .testimonials-row').flickity({
    pageDots: true,
    prevNextButtons: true,
    wrapAround: true,
    watchCSS: true,
    cellAlign: cellAlignValue,
  });

  $('.testimonials-section.grid').each(function () {
    $(this).find('.flickity-button-wrp .previous').click(function(){
      $(this).parent().siblings('.testimonials-row').find('.previous').click();
    });

    $(this).find('.flickity-button-wrp .next').click(function(){
      $(this).parent().siblings('.testimonials-row').find('.next').click();
    });
  });
}