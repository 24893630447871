/*
  Plays full width video player which has a youtube/vimeo link
*/

export function fullWidthVideoPlayerLink() {
  // for (let i = 1; i <= $('.playvideo.video-url').length ; i++) {
  //   let idName = 'play-video-' + i;
  //   let newIdName = 'video-iframe-' + i;
  //   $('.playvideo.video-url')[i-1].setAttribute('id', idName);
  //   $('.iframe-video.video-url')[i-1].setAttribute('id', newIdName);

  //   $('#'+idName).click(function(){
  //     if ($('#'+newIdName).hasClass('video-element')) {
  //       $('#'+ newIdName).css('opacity','1');
  //       $('#'+ newIdName).get(0).play();
  //       $('#' + idName).css({
  //         'opacity' : '0',
  //         'z-index' : '-1',
  //       });
  //     } else {
  //       $('#'+ newIdName).css('opacity','1');
  //       $('#'+ newIdName)[0].src += '&autoplay=1';
  //       $('#' + idName).css({
  //         'opacity' : '0',
  //         'z-index' : '-1',
  //       });
  //     }
  //   });
  // }

  $('.video-section.v1.link').each(function() {
    let videoOverlay = $(this).find('.video-overlay');

    $(videoOverlay).click(function(){
      let video = $(this).siblings('.iframe-video');
      let videoThumb = $(this).siblings('.video-thumb');

      video.css('opacity', '1');
      video[0].src += '&autoplay=1';
      videoThumb.css({
        'opacity' : '0',
        'z-index' : '-1',
      });
      videoOverlay.css({
        'opacity' : '0',
        'z-index' : '-1',
      });
    });
  });
}